@import '../../../../scss/theme-bootstrap';

.site-bag {
  @if $cr20 == true {
    @include unset-underline-links();
    margin: 0;
    height: $site-header-height;
    font-family: $ano-black_regular-font;
    @include breakpoint($bp--largest-up) {
      display: block;
      height: 32px;
      min-width: 16px;
      background-color: $color--black;
      &.active {
        background-color: $color--white;
        color: $color--black;
      }
    }
    &__link {
      @include unset-underline-links();
      width: 100%;
      font-size: 10px;
      text-align: center;
      text-transform: uppercase;
      height: 100%;
      line-height: 1;
      display: flex;
      align-items: center;
      position: relative;
      @include breakpoint($bp--largest-up) {
        display: inline;
        margin: 0 7px;
        padding: 0;
        height: $site-banner-height;
        line-height: $site-banner-height;
      }
      svg {
        width: 13px;
        height: 17px;
        fill: $color--white;
        .gnav-transparent--light:not(.site-header-formatter--sticky) & {
          fill: $color--white;
        }
        .gnav-transparent--dark:not(.site-header-formatter--sticky) & {
          fill: $color--black;
        }
      }
      .gnav-util-trigger:checked ~ & {
        background: $color--white;
      }
      .viewcart &,
      .checkout & {
        display: none;
        @include breakpoint($bp--large-up) {
          display: inline-block;
        }
      }
      &-icon {
        font-size: 22px;
        color: $color--white;
        @include breakpoint($bp--largest-up) {
          width: 18px;
          font-size: 17px;
          line-height: $site-banner-height;
          display: inline;
        }
        .gnav-util-trigger:checked ~ .site-bag__link &,
        .active & {
          color: $color--black;
          fill: $color--black;
        }
      }
      &-label {
        position: relative;
        cursor: pointer;
      }
      &-count {
        display: block;
        color: $color--black;
        height: auto;
        line-height: 1;
        text-align: center;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -15%);
        .gnav-transparent--light:not(.site-header-formatter--sticky) & {
          color: $color--white;
        }
        .gnav-transparent--dark:not(.site-header-formatter--sticky) & {
          color: $color--black;
        }
        .gnav-util-trigger:checked ~ .site-bag__link &,
        .active & {
          color: $color--white;
        }
      }
    }
    .gnav-util-trigger:checked + label.site-header-formatter-overlay {
      display: none;
    }
  }
  &__contents {
    @include body-mono();
    border: 1px solid $color--gray--lighter;
    background: $color--white;
    color: $color--black;
    display: none;
    width: 322px;
    position: absolute;
    #{$rdirection}: -1px;
    top: auto;
    text-align: $ldirection;
    z-index: calc(#{$gnav-fixed-z-index} + 1);
    @if $cr20 == true {
      &-edit {
        @include unset-underline-links();
        margin: 0 15px;
        float: #{$rdirection};
        text-transform: none;
        text-decoration: underline;
        &:hover {
          text-decoration: underline;
        }
        a {
          @include set-link-colors();
        }
      }
      &-title {
        @include h5();
        margin: 0;
        a {
          @include unset-underline-links();
          text-transform: none;
        }
        &__alt {
          display: none;
        }
      }
    }
    .gnav-util-trigger:checked ~ & {
      display: block;
      position: fixed;
    }
    a:not(.btn) {
      @include set-link-colors();
    }
    &-header {
      @include clearfix();
      padding: 15px;
      line-height: 30px;
    }
    &-close {
      @include unset-underline-links();
      float: #{$rdirection};
      font-size: 30px;
      i {
        vertical-align: top;
      }
    }
    &-products {
      overflow: auto;
    }
    .cart-product {
      @include clearfix();
      @include swap_direction(padding, 0 15px 15px 15px);
      &-img {
        @include unset-underline-links();
        @include swap_direction(margin, 0 15px 15px 0);
        float: #{$ldirection};
        img {
          max-height: 163px;
        }
      }
      &__bundle-link {
        pointer-events: none;
      }
      &-column {
        display: inline;
      }
      &-info {
        @include swap_direction(padding, 20px 0 0 0);
        min-height: 84px;
      }
      .cart-product-kit-shades {
        margin-top: 20px;
      }
      &-name {
        @include h6();
        margin: 0;
        line-height: 1;
        a {
          @include unset-underline-links();
          @if $cr20 == true {
            text-transform: none;
          }
        }
      }
      &-shade,
      &-preorder {
        padding: 10px 0;
        text-transform: uppercase;
      }
      &-preorder {
        font-weight: bold;
      }
      &-price {
        @include h6();
        @include swap_direction(margin, 0 0 10px 0);
        line-height: 1;
        &--sale {
          color: $color--red;
        }
        &--non-sale {
          text-decoration: line-through;
        }
      }
      &-button {
        @include btn;
        @include btn--full;
      }
      &-quantity {
        padding: 10px 0;
      }
    }
    &-footer {
      padding: 15px;
      border-top: 1px solid $color--gray--lighter;
    }
    &-subtotal {
      @include h6();
      margin: 0;
      text-align: #{$rdirection};
      &__title {
        float: #{$ldirection};
        text-align: #{$ldirection};
      }
    }
    &-checkout {
      @include btn;
      @include btn--full;
      @include set-link-defaults($extend: false);
    }
    &-message {
      margin: 0;
      text-align: center;
      a {
        @include unset-underline-links();
        text-transform: none;
      }
    }
    &-recommendations {
      padding: 0 15px;
      border-top: 1px solid $color--gray--lighter;
      display: none;
      @include breakpoint($bp--largest-up) {
        display: block;
      }
      &__title {
        @include h5();
        margin: 0;
        padding: 15px 0;
        cursor: pointer;
        line-height: 1;
        &-toggle {
          @include icon(arrow--down);
          display: inline;
          &:before {
            vertical-align: top;
          }
        }
      }
      &-carousel {
        display: none;
        position: relative;
        .slick-slider {
          margin: 0;
        }
        .cart-product {
          @include swap_direction(padding, 0 0 15px 0);
          &-column {
            width: 149px;
          }
        }
      }
      &-carousel__controls {
        margin: 0 auto;
        padding: 15px 0;
        border-top: 1px solid $color--gray--lighter;
        line-height: 1;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        z-index: 100;
        .slick-prev {
          background-image: image-url('global/sprites-carousel-arrows.png');
          background-repeat: no-repeat;
          background-position: 0 0;
          height: 7px;
          width: 58px;
          #{$ldirection}: 0;
        }
        .slick-next {
          background-image: image-url('global/sprites-carousel-arrows.png');
          background-repeat: no-repeat;
          background-position: 0 -7px;
          height: 7px;
          width: 58px;
          #{$rdirection}: 0;
        }
        .slick-prev,
        .slick-next {
          @include unset-underline-links($extend: true);
          @include swap_direction(margin, -5px 0 0 0);
          @include swap_direction(padding, 10px 0 0);
          display: inline-block;
          text-indent: -9999px;
          width: 58px;
        }
        .count {
          padding: 0 20px;
          display: inline-block;
          vertical-align: top;
        }
      }
      &.active {
        .site-bag__contents-recommendations__title-toggle {
          @include icon(arrow--up);
          &:before {
            vertical-align: top;
          }
        }
        .site-bag__contents-recommendations-carousel {
          display: block;
        }
      }
    }
  }
  &.active {
    background: $color--white;
    color: $color--black;
    .site-bag__contents {
      display: block;
    }
  }
  .kit-container {
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
    float: #{$ldirection};
    text-align: #{$ldirection};
    width: 46%;
    .cart-item {
      &__kit-item {
        @include swap_direction(margin, 0 0 5px 0);
        .product-name {
          display: none;
        }
      }
      &__desc-info {
        .color {
          .swatch {
            width: 20px;
            height: 20px;
            float: #{$ldirection};
          }
          .shade {
            @include swap_direction(margin, 0 0 0 28px);
            line-height: 1.6;
          }
        }
      }
    }
  }
  .cart-product-bundling-price {
    @include swap_direction(padding, 0 0 0 137px);
  }
}
